import React, { Dispatch, SetStateAction } from "react";
import { Doughnut } from "react-chartjs-2";

import {
  ChartLegend,
  ChartWrapper,
  DoughnutChart,
  MaterialAmount,
  MaterialBullet,
  MaterialItem,
  MaterialList,
  MaterialName,
  MaterialPercent,
  MaterialInput,
} from "./styled";
import { MaterialsData } from "interfaces/materials";

type Props = {
  isEditMode: boolean;
  materialsData: MaterialsData;
  setMaterialsData: Dispatch<SetStateAction<MaterialsData>>;
};

export default function Chart({
  isEditMode,
  materialsData,
  setMaterialsData,
}: Props) {
  const charData = {
    labels: materialsData.map((material) => material.name),
    datasets: [
      {
        data: materialsData.map((material) => material.value),
        backgroundColor: materialsData.map(
          (material) =>
            `rgb(${material.color.r}, ${material.color.g}, ${material.color.b})`
        ),
        hoverBackgroundColor: materialsData.map(
          (material) =>
            `rgba(${material.color.r}, ${material.color.g}, ${material.color.b}, 0.5)`
        ),
      },
    ],
  };

  const handleChangeMaterialAmount = (
    event: React.ChangeEvent<HTMLInputElement>,
    materialKey: string
  ) => {
    setMaterialsData((prevData) => {
      const newMaterialsData = prevData.map((material) =>
        material.key === materialKey
          ? { ...material, value: +event.target.value }
          : material
      );

      const materialsTotal = newMaterialsData.reduce(
        (acc, cur) => acc + cur.value,
        0
      );

      const nextMaterialsData = newMaterialsData.map((material) => ({
        ...material,
        value: Math.round((material.value / materialsTotal) * 100) || 0,
      }));

      const roundedTotal = nextMaterialsData.reduce(
        (acc, cur) => acc + cur.value,
        0
      );

      const roundingError = 100 - roundedTotal;

      const backgroundMaterial = nextMaterialsData.find(
        (material) => material.key === "Background"
      );

      if (backgroundMaterial && backgroundMaterial.value > 0) {
        backgroundMaterial.value += roundingError;
      } else {
        const firstGreatThanZeroMaterialIndex = nextMaterialsData.findIndex(
          (material) => material.value > 0
        );
        nextMaterialsData[firstGreatThanZeroMaterialIndex].value +=
          roundingError;
      }

      return nextMaterialsData;
    });
  };

  const renderMaterialItem = (material: MaterialsData[number]) => (
    <React.Fragment key={material.key}>
      <MaterialItem>
        <MaterialBullet
          style={{
            color: `rgb(${material.color.r}, ${material.color.g}, ${material.color.b})`,
          }}
        >
          &#8226;
        </MaterialBullet>
        <MaterialPercent>{material.value}%</MaterialPercent>
        <MaterialName>{material.name}</MaterialName>
      </MaterialItem>
      {isEditMode && (
        <MaterialAmount>
          <MaterialInput
            type="range"
            min="0"
            max="100"
            value={material.value}
            onChange={(event) =>
              handleChangeMaterialAmount(event, material.key)
            }
          />
        </MaterialAmount>
      )}
    </React.Fragment>
  );

  return (
    <ChartWrapper>
      <DoughnutChart>
        <Doughnut
          data={charData}
          width={207}
          height={207}
          options={{
            responsive: false,
            maintainAspectRatio: false,
            cutoutPercentage: 68,
            legend: { display: false },
          }}
        />
      </DoughnutChart>

      <ChartLegend>
        <MaterialList>
          {materialsData
            .slice(0, materialsData.length / 2)
            .map((material) => renderMaterialItem(material))}
        </MaterialList>
        <MaterialList>
          {materialsData
            .slice(materialsData.length / 2)
            .map((material) => renderMaterialItem(material))}
        </MaterialList>
      </ChartLegend>
    </ChartWrapper>
  );
}
