import styled from "styled-components";

import Devices from "theme/devices";

export const NavBar = styled.div`
  display: block;
`;

export const NavBarTop = styled.div`
  height: 40px;
  background-color: #333333;
  @media ${Devices.mobile} {
    display: none;
  }
`;

export const NavBarBottom = styled.div`
  height: 77px;
  background-color: #f5f5f5;
  padding-top: 25px;
  text-align: center;
  @media ${Devices.mobile} {
    height: 58px;
    padding-top: 15px;
  }
`;

export const NavBarBottomImg = styled.img`
  margin: 0 auto;
`;

export const NavBarTopInnerContent = styled.div`
  max-width: 1020px;
  width: 100%;
  padding: 0 20px;
  margin: 0 auto;
`;

export const NavBarTopList = styled.ul`
  display: inline-block;
  float: right;
  list-style: none;
  margin: 0;
  padding: 0;
  &.fl {
    float: left;
  }
  &.content-hide {
    @media ${Devices.tablet} {
      display: none;
    }
  }
`;

export const NavBarTopListItem = styled.li`
  display: inline-block;
  margin: 0;
  padding-right: 25px;
  float: left;
  font-size: 14px;
  line-height: 40px;

  &.content-hide {
    @media ${Devices.tablet} {
      display: none;
    }
  }
`;

export const NavBarTopListItemUrl = styled.a`
  color: #ffffff;
  text-decoration: none;
  outline: none;
  font-weight: 300;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
    color: #ffffff;
  }
`;
