import styled from "styled-components";

import Devices from "theme/devices";
import theme from "theme/theme";

export const FeedbackTitle = styled.h1`
  text-align: center;
  margin-bottom: 24px;
  @media ${Devices.mobile} {
    font-size: 40px;
  }
`;

export const FeedbackSubtitle = styled.p`
  text-align: center;
`;

export const FeedbackContent = styled.div`
  display: flex;
  align-items: center;
  margin-top: 80px;
  margin-bottom: 10px;
  @media ${Devices.tablet} {
    flex-direction: column;
  }
`;

export const FeedbackForm = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  padding: 0 25px;
`;

export const FeedbackFormTitle = styled.p`
  font-size: 25px;
  color: rgba(26, 29, 26, 0.8);
  padding-bottom: 20px;
  text-align: center;
  @media ${Devices.tablet} {
    text-align: left;
  }
`;

export const FeedbackFormInputs = styled.div`
  display: flex;
  @media ${Devices.tablet} {
    display: block;
  }
`;

export const FeedbackFormInputsLeft = styled.div`
  padding: 0 25px;
  @media ${Devices.tablet} {
    padding: 0;
  }
`;

export const FeedbackFormInputsRight = styled.div`
  padding: 0 25px;
  @media ${Devices.tablet} {
    padding: 0;
  }
`;

export const FeedbackFormButtons = styled.div`
  text-align: center;
  display: block;
  margin-top: 37px;
  @media ${Devices.tablet} {
    display: flex;
    margin: 37px auto 60px;
  }
`;

export const FeedbackLoadingCube = styled.div`
  flex: 1;
  padding: 0 120px;
  text-align: center;
`;

export const FeedbackLoadingCubeText = styled.h4`
  color: ${theme.brandPrimary};
`;
