import styled from "styled-components";

import Devices from "theme/devices";
import theme from "theme/theme";

export const Container = styled.div`
  max-width: 1147px;
  margin-left: auto;
  margin-right: auto;

  // Modifiers
  // ----------

  &.fullWidth {
    max-width: 100%;
  }
  &.section {
    border-top: 1px solid ${theme.grayLighter};
    border-bottom: 1px solid ${theme.grayLighter};
  }
  &.panel {
    padding: 20px;
    margin-bottom: 10px;
    background-color: ${theme.panelBg};
    border: none;
    @media ${Devices.mobile} {
      margin-bottom: 0px;
    }
  }
  &.stickers {
    padding: 60px 5% 42px;
    @media ${Devices.mobile} {
      padding-top: 30px;
    }
  }
`;

export const Image = styled.img`
  border-style: none;
  vertical-align: middle;
`;

// // About
// // ------

export const AboutWrapper = styled.div`
  text-align: center;
  padding: 15px 0 0;
`;

export const AboutDescription = styled.div`
  color: #6b6c6b;
  font-size: 14px;
`;

export const AboutTitleSection = styled.span`
  color: ${theme.brandPrimary};
`;

export const AboutTitle = styled.h1`
  @media ${Devices.mobile} {
    ext-align: center;
    font-size: 40px;
  }
`;

// // Footer
// // -------

export const FooterWrapper = styled.div`
  background-color: #333333;
`;

// // Stickers
// // ---------

export const StickersWrapper = styled.div`
  display: flex;
  @media ${Devices.mobile} {
    flex-direction: column;
    align-items: center;
  }
`;

export const Sticker = styled.div`
  flex: 1;
  padding: 0 5%;
  @media ${Devices.mobile} {
    flex-direction: row;
    padding: 20px;
  }
`;

export const StickerExplain = styled.div`
  padding-top: 12px;
  text-align: left;
`;

export const StickerTitle = styled.div`
  font-size: 20px;
  color: ${theme.brandPrimary};
  font-weight: ${theme.fontWeightBold};
  @media ${Devices.desktop} {
    font-size: 22px;
  }
`;

export const StickerDescription = styled.div`
  color: #6b6c6b;
  font-weight: ${theme.fontWeightLight};
  font-size: 14px;
  text-align: left;
  @media ${Devices.desktop} {
    font-size: 16px;
  }
`;

// Sponsors
// ---------

export const Sponsors = styled.div`
  text-align: center;
  background: #333333;
  margin: 0 auto;
  padding: 20px;
`;

export const SponsorsTitle = styled.h3`
  color: white;
  line-height: 50px;
`;

export const SponsorsList = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 30px 10% 20px;
  @media ${Devices.mobile} {
    padding: 30px 0 20px;
  }
`;

export const SponsorWrapper = styled.div`
  flex: 1 1 auto;
  margin: 10px 15px;
  @media ${Devices.mobile} {
    padding: 10px 10px;
  }
`;

export const SponsorSevenEleven = styled.img`
  width: 43px;
  height: 57px;
`;

export const SponsorWegmans = styled.img`
  width: 120px;
  height: 43px;
`;

export const SponsorFabricCom = styled.img`
  width: 114px;
  height: 31px;
`;

export const SponsorDavids = styled.img`
  width: 103px;
  height: 38px;
`;

export const Copyright = styled.p`
  color: white;
  font-weight: ${theme.fontWeightLight};
  padding-top: 20px;
  @media ${Devices.mobile} {
    padding-top: 30px;
  }
`;

// Customer
// ---

export const CustomerSubtitle = styled.div`
  padding-top: 36px;
  text-align: center;
  width: 860px;
  margin: 0 auto;
  @media ${Devices.desktop} {
    padding-top: 30px;
    width: 90%;
  }
  @media ${Devices.mobile} {
    padding-top: 10px;
  }
`;

export const CustomerSubtitleCopy = styled.h4`
  line-height: 30px;
  font-weight: ${theme.fontWeightLight};
`;

export const CustomerSubtitleBold = styled.span`
  font-weight: 600;
`;

export const CustomerSubtitlePrimary = styled.span`
  color: ${theme.brandPrimary};
`;
