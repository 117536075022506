import { SkCubeGrid, SkCube } from "./styled";

export default function LoadingCube() {
  return (
    <SkCubeGrid>
      <SkCube className="skCube1" />
      <SkCube className="skCube2" />
      <SkCube className="skCube3" />
      <SkCube className="skCube4" />
      <SkCube className="skCube5" />
      <SkCube className="skCube6" />
      <SkCube className="skCube7" />
      <SkCube className="skCube8" />
      <SkCube className="skCube9" />
    </SkCubeGrid>
  );
}
