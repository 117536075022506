import React, { useState } from "react";

import { Container } from "components/shared";
import { Customer } from "interfaces/customer";
import { MaterialsData } from "interfaces/materials";
import { Snap } from "./styled";
import DownloadPicture from "../DownloadPicture";
import Feedback from "../Feedback";
import GetStarted from "../GetStarted";
import Results from "components/Results";
import UploadPictureStep1 from "../UploadPictureSteps/UploadPictureStep1";
import UploadPictureStep2 from "../UploadPictureSteps/UploadPictureStep2";

export enum UPLOAD_STEPS {
  GET_START,
  FIRST,
  SECOND,
  RESULTS,
  FEEDBACK,
  DOWNLOAD,
}

type Props = {
  customerData: Customer;
  customerSubmitted: boolean;
  scrollToUploadPictureActionsSection: () => void;
};

export default React.forwardRef<HTMLDivElement, Props>(
  function UploadPictureActions(
    { customerData, customerSubmitted, scrollToUploadPictureActionsSection },
    uploadPictureActionsSectionRef
  ) {
    const [uploadStep, setUploadStep] = useState(UPLOAD_STEPS.GET_START);
    const [materialsData, setMaterialsData] = useState<MaterialsData>([]);

    const goToUploadStep = (step: UPLOAD_STEPS) => {
      setUploadStep(step);
      scrollToUploadPictureActionsSection();
    };

    const renderUploadStep = (step: UPLOAD_STEPS) => {
      switch (step) {
        case UPLOAD_STEPS.GET_START:
          return (
            <GetStarted
              customerSubmitted={customerSubmitted}
              handleGetStartClick={() => goToUploadStep(UPLOAD_STEPS.FIRST)}
            />
          );
        case UPLOAD_STEPS.FIRST:
          return (
            <UploadPictureStep1
              customerData={customerData}
              goToUploadStep={goToUploadStep}
              setMaterialsData={setMaterialsData}
            />
          );
        case UPLOAD_STEPS.SECOND:
          return (
            <UploadPictureStep2
              customerData={customerData}
              goToUploadStep={goToUploadStep}
            />
          );
        case UPLOAD_STEPS.RESULTS:
          return (
            <Results
              customerData={customerData}
              goToUploadStep={goToUploadStep}
              materialsData={materialsData}
            />
          );
        case UPLOAD_STEPS.FEEDBACK:
          return (
            <Feedback
              customerData={customerData}
              scrollToUploadPictureActionsSection={
                scrollToUploadPictureActionsSection
              }
              goToUploadStep={goToUploadStep}
            />
          );
        case UPLOAD_STEPS.DOWNLOAD:
          return (
            <DownloadPicture
              customerData={customerData}
              goToUploadStep={goToUploadStep}
            />
          );
      }
    };

    return (
      <Container
        ref={uploadPictureActionsSectionRef}
        className="fullWidth section"
      >
        <Container className="panel">
          <Snap>{renderUploadStep(uploadStep)}</Snap>
        </Container>
      </Container>
    );
  }
);
