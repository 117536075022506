import styled from "styled-components";

import Devices from "theme/devices";
import theme from "theme/theme";

export const ResultsWrapper = styled.div`
  text-align: center;
  h4 {
    font-size: 20px;
    color: ${theme.brandPrimary};
    text-align: center;

    margin-top: 45px;
    margin-bottom: 24px;
  }
`;

export const ResultsTitle = styled.h1`
  text-align: center;
  @media ${Devices.mobile} {
    font-size: 40px;
  }
`;

export const ResultsSubtitle = styled.p`
  text-align: center;
  margin-bottom: 82px;
  @media ${Devices.mobile} {
    margin-bottom: 50px;
  }
`;

export const ResultsButtons = styled.div`
  margin-top: 55px;
  text-align: center;

  button {
    @media ${Devices.mobile} {
      display: block;
      width: 95%;
      margin: 0 auto;
    }
    &.results-cancel {
      @media ${Devices.mobile} {
        margin: 0 auto 10px auto;
      }
    }
  }
  &.back-buttons {
    margin-top: 0;
    .results-cancel {
      @media ${Devices.mobile} {
        margin: 30px auto 10px auto;
      }
    }
  }
`;
