import styled from "styled-components";

import theme from "theme/theme";

export const PhotoUploadWrapper = styled.div<{ isHover: boolean }>`
  position: relative;
  display: block;
  width: 60%;
  margin: 0 auto 10px;
  padding: 10px 30px;
  overflow: hidden;
  background-color: rgba(
    0,
    165,
    153,
    ${(props) => (props.isHover ? "0.1" : "0.05")}
  );
  border: 1px dashed ${theme.brandPrimary};
  text-align: center;

  input {
    position: absolute;
    right: 0;
    top: 0;
    opacity: 0;
    outline: none;
    cursor: pointer;
    font-size: 200px;
  }

  @media (max-width: 700px) {
    width: 80%;
  }
`;

export const PhotoUploadTitle = styled.div`
  display: block;
  margin: 23px 0 23px;
  font-weight: ${theme.fontWeightBold};
  font-size: 20px;
  line-height: 33px;
  color: ${theme.brandPrimary};
`;

export const PhotoUploadExplain = styled.div`
  display: block;
  font-weight: ${theme.fontWeightMedium};
  font-size: 14px;
  margin-bottom: 7px;
`;
