import axios, { AxiosRequestConfig } from "axios";
import { apiBaseURLs } from "./environment";

const defaultHttpSettings: AxiosRequestConfig = {
  responseType: "json",
};

export const httpSnap = axios.create({
  ...defaultHttpSettings,
  baseURL: `${apiBaseURLs}/`,
});
