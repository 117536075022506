const theme = {
  // Colors
  // -------

  grayBase: "#000",
  grayDark: "#6B6C6B",
  grayDarker: "#444444",
  gray: "#4d4f53",
  grayLight: "#a4a4a4",
  grayLighter: "#E8E8E8",

  brandDefault: "#6B6C6B",
  brandPrimary: "#00A599",
  brandSuccess: "#00A599",
  brandInfo: "#095bb5",
  brandWarning: "#f37f00",
  brandDanger: "#d42727",

  materialPaper: "#4DAF50",
  materialOther: "#FFC107",
  materialPlastic: "#FF5723",
  materialOcc: "#7570F2",
  materialBackground: "#2AB6F6",
  materialOrganic: "#CDDA49",
  materialMetal: "#617D8A",
  materialBpb: "#785549",
  materialCans: "#7F00A5",
  materialBottles: "#A56500",

  // Scaffolding
  // ------------

  bodyBg: "#fff",
  textColor: "#6B6C6B",

  linkColor: "#00A599",
  linkHoverColor: "#007F73",
  linkHoverDecoration: "none",

  // Typography
  // -----------

  fontFamilyBase: "Ars Maquette, sans-serif",

  fontSizeBase: "14px",
  fontSizeLarge: Math.ceil(14 * 1.25) + "px", // ~18px
  fontSizeSmall: Math.ceil(14 * 0.85) + "px", // ~12px

  fontWeightLight: 300,
  fontWeightNormal: 400,
  fontWeightMedium: 500,
  fontWeightBold: 600,

  fontSizeH1: "58px",
  fontSizeH2: "46px",
  fontSizeH3: "36px",
  fontSizeH4: "21px",
  fontSizeH5: "14px",
  fontSizeH6: "12px",

  lineHeightBase: 1.428571429, // 20/14
  lineHeightComputed: Math.floor(14 * 1.428571429), // ~20px

  headingsFontFamily: "inherit",
  headingsFontWeight: 600,
  headingsLineHeight: 1.1,
  headingsColor: "#444444",
  headingsSmallColor: "#a4a4a4",

  hrBorder: "#a4a4a4",

  // Label
  // ------

  labelColor: "#6B6C6B",

  // Input
  // ------

  inputHeight: "38px",
  inputPadding: "6px 14px 8px",
  inputPaddingSelect: "6px 32px 8px 14px",
  inputBg: "#fff",
  inputBgDisabled: "#f2f2f2",
  inputBorder: "#4d4f53",
  inputBorderFocus: "#00A599",
  inputFontWeight: 300,
  inputColor: "#6B6C6B",
  inputPlaceholderColor: "#8c8e8c",

  // // Panel
  // // ------

  panelBg: "#FFF",
};

export default theme;
