import { Fragment, useState } from "react";

import { Button } from "components/Button";
import { Customer } from "interfaces/customer";
import { MaterialsData } from "interfaces/materials";
import { PHOTO_TYPE, uploadPhoto } from "services/uploadPhoto";
import {
  UploadPicture,
  UploadPictureError,
  UploadPictureMessage,
  UploadPicturePrimary,
  UploadPictureSubtitle,
  UploadPictureTitle,
} from "./styled";
import { UPLOAD_STEPS } from "components/UploadPictureActions";
import LoadingCube from "components/LoadingCube";
import PhotoUpload from "components/PhotoUpload";

type Props = {
  customerData: Customer;
  goToUploadStep: (step: UPLOAD_STEPS) => void;
  setMaterialsData: (materialsAmount: MaterialsData) => void;
};

export default function UploadPictureStep1({
  customerData,
  goToUploadStep,
  setMaterialsData,
}: Props) {
  const [isUploading, setIsUploading] = useState(false);
  const [showInvalidPhotoMessage, setShowInvalidPhotoMessage] = useState(false);

  const handlePhotoUpload = (file: File) => {
    if (!customerData) return;

    setIsUploading(true);
    uploadPhoto(file, customerData.uuid, PHOTO_TYPE.CLASSIFY)
      .then((data) => {
        setIsUploading(false);
        setMaterialsData(data);
        goToUploadStep(UPLOAD_STEPS.SECOND);
      })
      .catch(() => {
        setIsUploading(false);
        setShowInvalidPhotoMessage(true);
      });
  };

  return (
    <UploadPicture>
      {isUploading ? (
        <Fragment>
          <UploadPictureMessage>
            Processing your image...hang tight
          </UploadPictureMessage>
          <LoadingCube />
        </Fragment>
      ) : (
        <Fragment>
          <UploadPictureTitle>
            TRY: RUBICON<UploadPicturePrimary>Flash</UploadPicturePrimary>
          </UploadPictureTitle>
          <UploadPictureSubtitle>
            Upload a picture of your trash and sit back and let us help you find
            cost-saving opportunities
          </UploadPictureSubtitle>
          {showInvalidPhotoMessage && (
            <UploadPictureError>
              The picture you uploaded doesn't seem to be a picture with trash.
              <br /> Please upload another one.
            </UploadPictureError>
          )}

          <UploadPictureMessage>
            {!customerData?.currentcustomer && <span>Step 1/2: </span>}
            Upload a picture of your container's contents.
          </UploadPictureMessage>
          <PhotoUpload
            label="Drag & Drop Your Dumpster Photo Here"
            uploadPhoto={handlePhotoUpload}
          />
          <Button
            className="opposite back"
            onClick={() => goToUploadStep(UPLOAD_STEPS.GET_START)}
          >
            BACK
          </Button>
        </Fragment>
      )}
    </UploadPicture>
  );
}
