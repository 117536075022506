import {
  Container,
  Copyright,
  FooterWrapper,
  SponsorDavids,
  SponsorFabricCom,
  Sponsors,
  SponsorSevenEleven,
  SponsorsList,
  SponsorsTitle,
  SponsorWegmans,
  SponsorWrapper,
} from "components/shared";
import Davids from "assets/images/logo-davids.png";
import FabricCom from "assets/images/logo-fabric-com.png";
import SevenEleven from "assets/images/logo-7-eleven.png";
import Wegmans from "assets/images/logo-wegmans.png";

export default function Footer() {
  return (
    <FooterWrapper>
      <Container>
        <Sponsors>
          <SponsorsTitle>Trusted by Leading Brands</SponsorsTitle>
          <SponsorsList>
            <SponsorWrapper>
              <SponsorSevenEleven src={SevenEleven} />
            </SponsorWrapper>
            <SponsorWrapper>
              <SponsorWegmans src={Wegmans} />
            </SponsorWrapper>
            <SponsorWrapper>
              <SponsorFabricCom src={FabricCom} />
            </SponsorWrapper>
            <SponsorWrapper>
              <SponsorDavids src={Davids} />
            </SponsorWrapper>
          </SponsorsList>
          <Copyright>
            © {new Date().getFullYear()} Rubicon Global Holdings LLC. All rights
            reserved - 950 East Paces Ferry Road, Suite 1900 Atlanta, GA 30326
          </Copyright>
        </Sponsors>
      </Container>
    </FooterWrapper>
  );
}
