import {
  NavBar,
  NavBarBottom,
  NavBarBottomImg,
  NavBarTop,
  NavBarTopInnerContent,
  NavBarTopList,
  NavBarTopListItem,
  NavBarTopListItemUrl,
} from "./styled";
import logo from "assets/images/logo.svg";

export default function NavigationBar() {
  return (
    <NavBar>
      <NavBarTop>
        <NavBarTopInnerContent>
          <NavBarTopList className="fl">
            <NavBarTopListItem>
              <NavBarTopListItemUrl
                href="https://www.rubicon.com/"
                target="_blank"
              >
                Rubicon.com
              </NavBarTopListItemUrl>
            </NavBarTopListItem>
            <NavBarTopListItem className="content-hide">
              <NavBarTopListItemUrl
                href="https://customer.rubicon.com/"
                target="_blank"
              >
                Customer Login
              </NavBarTopListItemUrl>
            </NavBarTopListItem>
            <NavBarTopListItem className="content-hide">
              <NavBarTopListItemUrl
                href="https://hauler.rubicon.com/"
                target="_blank"
              >
                Hauler Login
              </NavBarTopListItemUrl>
            </NavBarTopListItem>
          </NavBarTopList>
          <NavBarTopList className="content-hide">
            <NavBarTopListItem>
              <NavBarTopListItemUrl
                href="https://www.rubicon.com/contact-us/"
                target="_blank"
              >
                Contact Us
              </NavBarTopListItemUrl>
            </NavBarTopListItem>
          </NavBarTopList>
        </NavBarTopInnerContent>
      </NavBarTop>
      <NavBarBottom>
        <NavBarBottomImg src={logo} />
      </NavBarBottom>
    </NavBar>
  );
}
