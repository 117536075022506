import { Fragment } from "react";

import { Button } from "components/Button";
import {
  GetStartedInstructions,
  GetStartedPrimary,
  GetStartedSubtitle,
  GetStartedTitle,
  GetStartedWarning,
  InstructionsImage,
  InstructionsImageWrapper,
  InstructionsInformation,
  InstructionsInformationList,
  InstructionsInformationListItem,
  InstructionsInformationListSubItem,
  InstructionsInformationSubcategory,
  InstructionsInformationTitle,
} from "./styled";
import instructionsImage from "assets/images/bin.png";

type Props = {
  customerSubmitted: boolean;
  handleGetStartClick: () => void;
};

export default function GetStarted({
  customerSubmitted,
  handleGetStartClick,
}: Props) {
  return (
    <Fragment>
      <GetStartedTitle>
        TRY: RUBICON<GetStartedPrimary>Flash</GetStartedPrimary>
      </GetStartedTitle>
      <GetStartedSubtitle>
        Upload a picture of your trash and sit back and let us help you find
        cost-saving opportunities
      </GetStartedSubtitle>
      <GetStartedInstructions>
        <InstructionsImageWrapper>
          <InstructionsImage src={instructionsImage} />
        </InstructionsImageWrapper>
        <InstructionsInformation>
          <InstructionsInformationTitle>
            Guidelines
          </InstructionsInformationTitle>
          <InstructionsInformationSubcategory>
            For pictures of your trash:
          </InstructionsInformationSubcategory>
          <InstructionsInformationList>
            <InstructionsInformationListItem>
              Day lighting
              <InstructionsInformationListSubItem>
                Even trash looks best in natural light!
              </InstructionsInformationListSubItem>
            </InstructionsInformationListItem>
            <InstructionsInformationListItem>
              Open Bag
              <InstructionsInformationListSubItem>
                Don’t cover up for us. Open those bags and show us what you got!
              </InstructionsInformationListSubItem>
            </InstructionsInformationListItem>
          </InstructionsInformationList>

          <InstructionsInformationSubcategory>
            For pictures of your dumpster:
          </InstructionsInformationSubcategory>
          <InstructionsInformationList>
            <InstructionsInformationListItem>
              Big Pic
              <InstructionsInformationListSubItem>
                Bigger is better. We want to see your dumpster!
              </InstructionsInformationListSubItem>
            </InstructionsInformationListItem>
            <InstructionsInformationListItem>
              Angle Shot
              <InstructionsInformationListSubItem>
                Selfie Sticks encouraged. Show us your dumpster’s best angle!
              </InstructionsInformationListSubItem>
            </InstructionsInformationListItem>
          </InstructionsInformationList>
          <Button
            className="getStarted"
            onClick={handleGetStartClick}
            disabled={!customerSubmitted}
          >
            UPLOAD A PICTURE
          </Button>
          {!customerSubmitted && (
            <GetStartedWarning>
              Please submit your Email, Zipcode and click Get Started at the top
              of the page.
            </GetStartedWarning>
          )}
        </InstructionsInformation>
      </GetStartedInstructions>
    </Fragment>
  );
}
