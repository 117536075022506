import React, { Fragment, useState } from "react";

import { Button } from "components/Button";
import { Customer } from "interfaces/customer";
import { CustomerSubtitlePrimary } from "components/shared";
import {
  CustomerContent,
  CustomerDescription,
  CustomerPicture,
  CustomerText,
  CustomerTextCopy,
  CustomerTextForm,
  CustomerWrapper,
  CustomerLoadingCubeText,
} from "./styled";
import {
  FormControl,
  FormGroup,
  FormGroupHelp,
  FormGroupOptions,
  Label,
} from "components/Form";
import { preventSubmitOnEnter } from "utils";
import { sendCustomer } from "services/customer";
import { useInput } from "../../utils/hooks";
import { validEmailRegex, validZipCodeRegex } from "constants/regex";
import backgroundHand from "assets/images/background-hand.png";
import LoadingCube from "components/LoadingCube";

type Props = {
  customerSubmitted: boolean;
  scrollToUploadPictureActionsSection: () => void;
  setCustomerSubmitted: (customerSubmitted: boolean) => void;
  setCustomerData: (customerData: Customer) => void;
  customerData: Customer;
};

export default function CustomerForm({
  customerSubmitted,
  scrollToUploadPictureActionsSection,
  setCustomerSubmitted,
  setCustomerData,
  customerData,
}: Props) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [currentCustomer, setCurrentCustomer] = useState(false);

  const email = useInput("");
  const zipCode = useInput("");

  const handleSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault();
    if (!zipCode.value || !email.value) {
      if (!zipCode.value) {
        zipCode.setError("You can't leave this empty.");
      }
      if (!email.value) {
        email.setError("You can't leave this empty.");
      }
      return;
    }

    if (
      !validEmailRegex.test(String(email.value)) ||
      !validZipCodeRegex.test(String(zipCode.value))
    ) {
      if (!validEmailRegex.test(String(email.value))) {
        email.setError("Please provide a valid email.");
      }
      if (!validZipCodeRegex.test(String(zipCode.value))) {
        zipCode.setError("Zipcode must be a five digit number.");
      }
      return;
    }

    setIsSubmitting(true);
    sendCustomer({
      zipcode: String(zipCode.value),
      uuid: customerData?.uuid || "",
      email: String(email.value),
      currentcustomer: currentCustomer,
    }).then((data) => {
      setCustomerData(
        (customerData = {
          uuid: data.Uuid,
          zipcode: data.ZipCode,
          email: data.Email,
          currentcustomer: data.CurrentCustomer,
        })
      );
      setCustomerSubmitted(true);
      setIsSubmitting(false);
      scrollToUploadPictureActionsSection();
    });
  };

  return (
    <CustomerWrapper>
      <CustomerContent>
        <CustomerDescription>
          <CustomerText>
            <CustomerTextCopy>
              Use RUBICON
              <CustomerSubtitlePrimary>Flash</CustomerSubtitlePrimary> to
              analyze your trash for recyclables and savings
            </CustomerTextCopy>
            <CustomerTextForm>
              {isSubmitting ? (
                <Fragment>
                  <CustomerLoadingCubeText>
                    Sending your data...
                  </CustomerLoadingCubeText>
                  <LoadingCube />
                </Fragment>
              ) : (
                <form
                  name="home"
                  onSubmit={handleSubmit}
                  noValidate
                  onKeyPress={preventSubmitOnEnter}
                >
                  <FormGroup
                    className="withLabel customerInput"
                    hasError={!!email.error}
                  >
                    <FormControl
                      type="email"
                      name="email"
                      disabled={customerSubmitted}
                      placeholder="Email"
                      {...email.bind}
                      required
                    />
                    {email.error && (
                      <FormGroupHelp>
                        <span>{email.error}</span>
                      </FormGroupHelp>
                    )}
                  </FormGroup>
                  <FormGroup
                    className="withLabel customerInput"
                    hasError={!!zipCode.error}
                  >
                    <FormControl
                      name="zipCode"
                      placeholder="Zip Code"
                      {...zipCode.bind}
                      disabled={customerSubmitted}
                      required
                    />
                    {zipCode.error && (
                      <FormGroupHelp>
                        <span>{zipCode.error}</span>
                      </FormGroupHelp>
                    )}
                  </FormGroup>
                  <FormGroup className="withLabel customerInput">
                    <Label>Are you a current Rubicon customer?</Label>
                    <FormGroupOptions>
                      <Label>
                        <FormControl
                          type="radio"
                          name="currentCustomer"
                          className="radio"
                          value="true"
                          disabled={customerSubmitted}
                          onChange={() => {
                            setCurrentCustomer(true);
                          }}
                          checked={currentCustomer}
                        />
                        <span>Yes</span>
                      </Label>
                      <Label>
                        <FormControl
                          type="radio"
                          name="currentCustomer"
                          className="radio"
                          value="false"
                          disabled={customerSubmitted}
                          onChange={() => {
                            setCurrentCustomer(false);
                          }}
                          checked={!currentCustomer}
                        />
                        <span>No</span>
                      </Label>
                    </FormGroupOptions>
                  </FormGroup>

                  <Button
                    type="submit"
                    className="customerSubmit"
                    disabled={customerSubmitted || isSubmitting}
                  >
                    Get Started!
                  </Button>
                </form>
              )}
            </CustomerTextForm>
          </CustomerText>
          <CustomerPicture src={backgroundHand} />
        </CustomerDescription>
      </CustomerContent>
    </CustomerWrapper>
  );
}
