import styled from "styled-components";

import Devices from "theme/devices";
import theme from "theme/theme";

export const UploadPicture = styled.div`
  text-align: center;
`;

export const UploadPictureTitle = styled.h1`
  text-align: center;
  @media ${Devices.mobile} {
    font-size: 40px;
  }
`;

export const UploadPicturePrimary = styled.span`
  color: ${theme.brandPrimary};
`;

export const UploadPictureError = styled.div`
  width: 60%;
  margin: 40px auto;
  padding: 40px 80px;
  background-color: rgba(212, 39, 39, 0.05);
  border: 1px solid ${theme.brandDanger};
  color: ${theme.brandDanger};
`;

export const UploadPictureSubtitle = styled.p`
  text-align: center;
  margin-bottom: 35px;
`;

export const UploadPictureMessage = styled.h4`
  font-size: 20px;
  color: ${theme.brandPrimary};
  text-align: center;
  margin-bottom: 24px;
`;
