import theme from "./theme";

const typography = `
  // Headings
  // ---------

  h1, h2, h3, h4, h5, h6,
  .h1, .h2, .h3, .h4, .h5, .h6 {
    font-family: ${theme.headingsFontFamily};
    line-height: ${theme.headingsLineHeight};
    font-weight: ${theme.headingsFontWeight};
    color: ${theme.headingsColor};

    small,
    .small {
      line-height: 1;
      font-weight: normal;
      color: ${theme.headingsSmallColor};
    }
  }

  h1, .h1,
  h2, .h2,
  h3, .h3 {
    margin-top: ${theme.lineHeightComputed}px;
    margin-bottom: ${theme.lineHeightComputed / 2}px;

    small,
    .small {
      font-size: 65%;
    }
  }

  h4, .h4,
  h5, .h5,
  h6, .h6 {
    margin-top: ${theme.lineHeightComputed / 2}px;
    margin-bottom: ${theme.lineHeightComputed / 2}px;

    small,
    .small {
      font-size: 75%;
    }
  }

  h1, .h1 {
    font-size: ${theme.fontSizeH1};
  }

  h2, .h2 {
    font-size: ${theme.fontSizeH2};
  }

  h3, .h3 {
    font-size: ${theme.fontSizeH3};
  }

  h4, .h4 {
    font-size: ${theme.fontSizeH4};
  }

  h5, .h5 {
    font-size: ${theme.fontSizeH5};
  }

  h6, .h6 {
    font-size: ${theme.fontSizeH6};
  }

  // Body text
  // ----------

  p {
    margin: 0 0 (${theme.lineHeightComputed} / 2)px;
  }

  // Emphasis & misc
  // ----------------

  small,
  .small {
    font-size: floor((100% * ${theme.fontSizeSmall} / ${theme.fontSizeBase}));
  }

  // Lists
  // ------

  ul,
  ol {
    margin-top: 0;
    margin-bottom: (${theme.lineHeightComputed} / 2);

    ul,
    ol {
      margin-bottom: 0;
    }
  }

  // Un-styled lists
  // ----------------

  %list-unstyled {
    padding-left: 0;
    list-style: none;
  }

  // Description Lists
  // ------------------

  dl {
    margin-top: 0;
    margin-bottom: ${theme.lineHeightComputed};
  }

  dt,
  dd {
    line-height: ${theme.lineHeightBase};
  }

  dt {
    font-weight: bold;
  }

  dd {
    margin-left: 0;
  }
  `;

export default typography;
