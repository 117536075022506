import styled, { css } from "styled-components";

import Devices from "theme/devices";
import dropdownArrow from "assets/images/dropdown-arrow.svg";
import theme from "theme/theme";

export const Label = styled.label`
  display: inline-block;
  margin-bottom: 10px;
  font-weight: ${theme.fontWeightBold};
  font-size: 12px;
  color: rgba(0, 0, 0, 0.8);
  &.checkbox,
  &.radio {
    font-weight: ${theme.fontWeightMedium};
  }
  & + & {
    margin-left: 14px;
  }
`;

// Form control
// -------------

export const FormControlStyle = `
  display: block;
  width: 100%;
  height: ${theme.inputHeight};
  padding: ${theme.inputPadding};
  background-color: ${theme.inputBg};
  outline: none;
  border-radius: 0;
  border: 1px solid ${theme.inputBorder};
  font-weight: ${theme.inputFontWeight};
  color: ${theme.inputColor};
  ::placeholder {
    color: ${theme.inputPlaceholderColor};
  }
  &:focus {
    border-color: ${theme.inputBorderFocus};
    box-shadow: 0 0 2px rgba(0, 165, 153, 0.8);
  }

  &:disabled {
    background-color: ${theme.inputBgDisabled};
  }
`;

interface FormControlProps {
  select?: boolean;
  checkboxRadio?: boolean;
}

export const FormControl = styled.input<FormControlProps>`
  ${FormControlStyle};
  &.checkbox,
  &.radio {
    display: inline-block;
    width: auto;
    height: auto;
    margin-right: 6px;
  }
  &.moreSpace {
    padding-left: 10px;
    padding-right: 10px;
  }
`;

export const FormControlSelect = styled.select`
  ${FormControlStyle};
  padding: ${theme.inputPaddingSelect};
  background: ${theme.inputBg} url(${dropdownArrow}) right 12px top 16px
    no-repeat;
  appearance: none;
  &.moreSpace {
    padding-left: 10px;
    padding-right: 10px;
  }
`;

interface FormGroupProps {
  hasError?: boolean;
}

export const FormGroup = styled.div<FormGroupProps>`
  position: relative;
  margin-bottom: 10px;
  width: 256px;
  &.withLabel {
    margin-bottom: 20px;
  }
  &.customMargin {
    margin-top: 15px;
    @media ${Devices.tablet} {
      margin-top: 0px;
    }
  }
  &.customerInput {
    @media ${Devices.mobile} {
      margin: 0 auto 10px;
    }
  }
  ${(props) =>
    props.hasError &&
    css`
      ${FormControl} {
        border-color: ${theme.brandDanger};
        &:focus {
          box-shadow: 0 0 2px rgba(212, 39, 39, 0.8);
        }
      }
    `}
`;

export const FormGroupHelp = styled.div`
  color: ${theme.brandDanger};
  display: block;
  margin-top: 5px;
  font-size: 13px;
`;

export const FormGroupOptions = styled.div`
  ${Label} {
    position: relative;
    padding-left: 22px;
  }
  ${FormControl} {
    display: none;
    + span {
      &:before {
        content: "";
        background: white;
        width: 15px;
        height: 15px;
        position: absolute;
        left: 0;
        top: 1px;
        border: 1px solid rgba(0, 0, 0, 0.8);
        border-radius: 100%;
      }
    }
    &:checked + span {
      &:before {
        border: 1px solid ${theme.brandPrimary};
      }

      &:after {
        content: "";
        background: ${theme.brandPrimary};
        width: 7px;
        height: 7px;
        position: absolute;
        left: 4px;
        top: 5px;
        border-radius: 100%;
      }
    }
  }
`;
