import styled from "styled-components";

import Devices from "theme/devices";
import theme from "theme/theme";

export const Download = styled.div`
  display: flex;
  align-items: center;
  @media ${Devices.tablet} {
    flex-direction: column;
  }
`;

export const DownloadMessage = styled.div`
  flex: 1;
  text-align: center;
  padding: 0 120px;
  font-size: 14px;
  color: ${theme.brandPrimary};
  @media ${Devices.tablet} {
    margin-bottom: 60px;
    text-align: center;
    padding: 0 20px;
  }
`;

export const DownloadMessageTitle = styled.div`
  margin-bottom: 30px;
`;

export const DownloadMessageBody = styled.div`
  font-size: 14px;
`;

export const DownloadMessageEnd = styled.div`
  margin-top: 30px;
`;

export const DownloadMessageSignature = styled.div`
  margin-top: 10px;
`;

export const DownloadPictureWrapper = styled.div`
  flex: 1;
  padding: 0 25px;
  text-align: center;
  @media ${Devices.tablet} {
    max-width: 460px;
  }
`;

export const DownloadPicturePreview = styled.img`
  max-width: 100%;
  align-self: center;
`;

export const DownloadPictureButton = styled.a`
  display: inline-block;
  margin: 50px auto 0;
  padding: 11px 36.5px 9px;
  border: none;
  border-radius: 1px;
  text-transform: uppercase;
  letter-spacing: 1px;
  line-height: 18px;
  font-weight: ${theme.fontWeightMedium};
  font-size: 14px;
  line-height: 22px;
  background: ${theme.brandPrimary};
  color: white;
  cursor: pointer;

  &:hover {
    color: white;
  }
`;

export const ButtonWrapper = styled.div`
  text-align: center;
  margin-top: 40px;
`;
