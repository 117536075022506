import { Fragment, useState } from "react";

import { Button } from "components/Button";
import { Customer } from "interfaces/customer";
import { MaterialsData } from "interfaces/materials";
import {
  ResultsButtons,
  ResultsSubtitle,
  ResultsTitle,
  ResultsWrapper,
} from "./styled";
import { sendFeedback } from "services/customer";
import { sendTweaked } from "services/tweak";
import { UPLOAD_STEPS } from "components/UploadPictureActions";
import DoughnutChart from "components/Chart";
import LoadingCube from "components/LoadingCube";

type Props = {
  customerData: Customer;
  goToUploadStep: (step: UPLOAD_STEPS) => void;
  materialsData: MaterialsData;
};

export default function Results({
  customerData,
  goToUploadStep,
  materialsData: initialMaterialsData,
}: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [materialsData, setMaterialsData] = useState(initialMaterialsData);

  const backgroundThreshold =
    (initialMaterialsData.find((material) => material.key === "Background")
      ?.value || 0) <= 75;

  const handleToggleEditMode = () => {
    if (isEditMode) {
      setMaterialsData(initialMaterialsData);
      setIsEditMode(false);
    } else {
      setIsEditMode(true);
    }
  };

  const handleSave = () => {
    if (!customerData) return;

    setIsLoading(true);
    sendTweaked(customerData.uuid, materialsData)
      .then(() => {
        // If it's an existing customer, skip the feedback page and
        // post just the email and zipCode to the feedback endpoint
        if (customerData.currentcustomer) {
          sendFeedback({
            currentcustomer: customerData.currentcustomer,
            email: customerData.email,
            uuid: customerData.uuid,
            zipcode: customerData.zipcode,
          })
            .then(() => {
              setIsLoading(false);
              goToUploadStep(UPLOAD_STEPS.DOWNLOAD);
            })
            .catch(() => {
              window.alert("Please Try Again");
              setIsLoading(false);
            });
        } else {
          setIsLoading(false);
          goToUploadStep(UPLOAD_STEPS.FEEDBACK);
        }
      })
      .catch(() => {
        window.alert("Sending data failed. Please try again.");
        setIsLoading(false);
      });
  };

  return (
    <ResultsWrapper>
      {isLoading ? (
        <Fragment>
          <h4>Sending your data...</h4>
          <LoadingCube />
        </Fragment>
      ) : (
        <Fragment>
          <ResultsTitle>The Results Are In</ResultsTitle>

          <ResultsSubtitle>
            Thanks for your patience. Here’s what we found:
          </ResultsSubtitle>

          <DoughnutChart
            isEditMode={isEditMode}
            materialsData={materialsData}
            setMaterialsData={setMaterialsData}
          />

          <ResultsButtons>
            <Button
              className="opposite results-cancel"
              onClick={handleToggleEditMode}
            >
              {isEditMode ? "Cancel" : "Edit Results"}
            </Button>
            <Button onClick={handleSave}>Save & Continue</Button>
          </ResultsButtons>

          {!backgroundThreshold && (
            <h4>
              We are seeing a lot of things that don't look like trash. Are you
              sure it is trash?
            </h4>
          )}

          <ResultsButtons className="back-buttons">
            <Button
              className="opposite results-cancel back"
              onClick={() => goToUploadStep(UPLOAD_STEPS.SECOND)}
            >
              BACK
            </Button>
            {!backgroundThreshold && (
              <Button onClick={handleSave}>Confirm</Button>
            )}
          </ResultsButtons>
        </Fragment>
      )}
    </ResultsWrapper>
  );
}
