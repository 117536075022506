import { createGlobalStyle } from "styled-components";

import fonts from "./fonts";
import theme from "./theme";
import typography from "./typography";

const GlobalStyle = createGlobalStyle`
  ${fonts}
  ${typography}
  
  // Reset the box-sizing
  // ---------------------  

  * {
  box-sizing: border-box;
  }

  *:before,
  *:after {
    box-sizing: border-box;
  }

  // Body reset
  // ------------

  html {
    font-size: 10px;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  body {
    background: ${theme.bodyBg};
    text-rendering: optimizeLegibility;
    font-family: ${theme.fontFamilyBase};
    font-size: ${theme.fontSizeBase};
    line-height: ${theme.lineHeightBase};
    color: ${theme.textColor};
  }

  // Reset fonts for relevant elements
  // ----------------------------------

  input,
  button,
  select,
  textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
  }

  // Links
  // ------

  a {
    color: ${theme.linkColor};
    text-decoration: none;
    outline: none;

    &:hover {
      color: ${theme.linkHoverColor};
      text-decoration: ${theme.linkHoverDecoration};
    }
  }

  // Figures
  // --------

  figure {
    margin: 0;
  }

  // Images
  // -------

  img {
    vertical-align: middle;
  }

  // Horizontal rules
  // -----------------

  hr {
    margin-top: ${theme.lineHeightComputed};
    margin-bottom: ${theme.lineHeightComputed};
    border: 0;
    border-top: 1px solid ${theme.hrBorder};
  }

  p {
    margin: 0 0 10px;
  }
  
  ul, ol {
    margin-top: 0;
    margin-bottom: 10px;
  }
`;

export default GlobalStyle;
