import {
  Container,
  Image,
  Sticker,
  StickerDescription,
  StickerExplain,
  StickersWrapper,
  StickerTitle,
} from "components/shared";
import CostSavings from "assets/images/icon-cost-savings.svg";
import Sustainability from "assets/images/icon-sustainability.svg";
import Technology from "assets/images/icon-technology.svg";

export default function StickersAbout() {
  return (
    <Container className="stickers">
      <StickersWrapper>
        <Sticker>
          <Image src={Technology} />
          <StickerExplain>
            <StickerTitle>Technology</StickerTitle>
            <StickerDescription>
              RUBICONFlash utilizes Artificial Intelligence to quickly analyze
              your waste mix.
            </StickerDescription>
          </StickerExplain>
        </Sticker>
        <Sticker>
          <Image src={CostSavings} />
          <StickerExplain>
            <StickerTitle>Cost Savings</StickerTitle>
            <StickerDescription>
              Upload your dumpster photo and we can tell you whether you’re
              overpaying.
            </StickerDescription>
          </StickerExplain>
        </Sticker>
        <Sticker>
          <Image src={Sustainability} />
          <StickerExplain>
            <StickerTitle>Sustainability</StickerTitle>
            <StickerDescription>
              Our waste experts can then consult on sustainable and affordable
              waste diversion.
            </StickerDescription>
          </StickerExplain>
        </Sticker>
      </StickersWrapper>
    </Container>
  );
}
