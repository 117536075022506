import React, { useRef, useState } from "react";

import { Button } from "components/Button";
import {
  PhotoUploadWrapper,
  PhotoUploadExplain,
  PhotoUploadTitle,
} from "./styled";

const PHOTO_MIME_TYPES = ["image/jpeg", "image/png", "image/tiff"];

const MAX_FILE_SIZE = 15 * 1024 * 1024;

type Props = {
  label: string;
  uploadPhoto: (file: File) => void;
};

export default function PhotoUpload({ label, uploadPhoto }: Props) {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [dragCounter, setDragCounter] = useState(0);

  const isValidItem = (item: DataTransferItem) =>
    item.kind === "file" && PHOTO_MIME_TYPES.includes(item.type);

  const isValidPhoto = (file: File) =>
    PHOTO_MIME_TYPES.includes(file.type) && file.size <= MAX_FILE_SIZE;

  const handleDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
    if (event.dataTransfer && isValidItem(event.dataTransfer.items[0])) {
      setDragCounter((prevValue) => prevValue + 1);
    }
  };

  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    if (event.dataTransfer && isValidItem(event.dataTransfer.items[0])) {
      setDragCounter((prevValue) => prevValue - 1);
    }
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();

    if (event.dataTransfer && isValidPhoto(event.dataTransfer.files[0])) {
      uploadPhoto(event.dataTransfer.files[0]);
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const openPhotoUploader = () => {
    fileInputRef.current?.click();
  };

  const handleFileInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files && isValidPhoto(event.target.files[0])) {
      uploadPhoto(event.target.files[0]);
    }
  };

  return (
    <PhotoUploadWrapper
      isHover={dragCounter > 0}
      onDragEnterCapture={handleDragEnter}
      onDragLeaveCapture={handleDragLeave}
      onDragOverCapture={handleDragOver}
      onDrop={handleDrop}
    >
      <PhotoUploadTitle>{label}</PhotoUploadTitle>
      <Button style={{ marginBottom: "47px" }} onClick={openPhotoUploader}>
        SELECT FILE
      </Button>
      <PhotoUploadExplain>
        .JPG, .PNG & .TIFF formats only. Max. file size 15MB
      </PhotoUploadExplain>
      <input
        type="file"
        ref={fileInputRef}
        accept={PHOTO_MIME_TYPES.join()}
        style={{ display: "none" }}
        onChange={handleFileInputChange}
      />
    </PhotoUploadWrapper>
  );
}
