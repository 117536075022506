import { Fragment, useState, useEffect } from "react";

import { Button } from "components/Button";
import {
  ButtonWrapper,
  Download,
  DownloadPicturePreview,
  DownloadPictureButton,
  DownloadPictureWrapper,
  DownloadMessage,
  DownloadMessageEnd,
  DownloadMessageSignature,
  DownloadMessageTitle,
} from "./styled";
import { Customer } from "interfaces/customer";
import { getPicture } from "services/customer";
import { UPLOAD_STEPS } from "components/UploadPictureActions";

type Props = {
  customerData: Customer;
  goToUploadStep: (step: UPLOAD_STEPS) => void;
};

export default function DownloadPicture({
  customerData,
  goToUploadStep,
}: Props) {
  const [downloadPictureUrl, setDownloadPictureUrl] = useState("");

  useEffect(() => {
    if (!customerData?.uuid) return;
    if (customerData.uuid)
      getPicture(customerData.uuid).then((data) => {
        setDownloadPictureUrl(data);
      });
  }, [customerData?.uuid]);

  return (
    <Fragment>
      <Download>
        <DownloadMessage>
          <DownloadMessageTitle>Thanks for your response!</DownloadMessageTitle>
          {!customerData?.currentcustomer && (
            <div>
              A Rubicon representative will get in touch with you soon with a
              customized offer for your business.
            </div>
          )}
          {customerData?.currentcustomer && (
            <div>We have emailed you a copy of your results.</div>
          )}
          <div>
            In the meantime, feel free to download this picture – we turned your
            trash into art!
          </div>
          <DownloadMessageEnd>Have a nice day,</DownloadMessageEnd>
          <DownloadMessageSignature>The Rubicon Team.</DownloadMessageSignature>
        </DownloadMessage>
        <DownloadPictureWrapper>
          {!!downloadPictureUrl && (
            <Fragment>
              <DownloadPicturePreview src={downloadPictureUrl} />
              <DownloadPictureButton href={downloadPictureUrl} target="_blank">
                DOWNLOAD PICTURE
              </DownloadPictureButton>
            </Fragment>
          )}
        </DownloadPictureWrapper>
      </Download>
      <ButtonWrapper>
        <Button
          className="opposite"
          onClick={() => goToUploadStep(UPLOAD_STEPS.FEEDBACK)}
        >
          BACK
        </Button>
      </ButtonWrapper>
    </Fragment>
  );
}
