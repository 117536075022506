import { Customer, Feedback } from "interfaces/customer";
import { httpSnap } from "services/http";

export const sendCustomer = (data: Customer) =>
  httpSnap.post("customer/", data).then((response) => response.data);

export const sendFeedback = (data: Feedback) =>
  httpSnap.post("customer/extended/", data).then((response) => response.data);

export const getPicture = (uuid: string) =>
  httpSnap.get(`art/${uuid}`).then((response) => response.data);
