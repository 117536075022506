import { Fragment, useState } from "react";

import { Button } from "components/Button";
import { Customer } from "interfaces/customer";
import { PHOTO_TYPE, uploadPhoto } from "services/uploadPhoto";
import {
  UploadPicture,
  UploadPictureMessage,
  UploadPicturePrimary,
  UploadPictureSubtitle,
  UploadPictureTitle,
} from "./styled";
import { UPLOAD_STEPS } from "components/UploadPictureActions";
import LoadingCube from "components/LoadingCube";
import PhotoUpload from "components/PhotoUpload";

type Props = {
  customerData: Customer;
  goToUploadStep: (step: UPLOAD_STEPS) => void;
};

export default function UploadPictureStep2({
  customerData,
  goToUploadStep,
}: Props) {
  const [isUploading, setIsUploading] = useState(false);

  const handlePhotoUpload = (file: File) => {
    if (!customerData) return;

    setIsUploading(true);
    uploadPhoto(file, customerData.uuid, PHOTO_TYPE.TRASH_BIN)
      .then(() => {
        setIsUploading(false);
        goToUploadStep(UPLOAD_STEPS.RESULTS);
      })
      .catch(() => {
        setIsUploading(false);
      });
  };

  return (
    <UploadPicture>
      {isUploading ? (
        <Fragment>
          <UploadPictureMessage>
            Processing your image...hang tight
          </UploadPictureMessage>
          <LoadingCube />
        </Fragment>
      ) : (
        <Fragment>
          <UploadPictureTitle>
            TRY: RUBICON<UploadPicturePrimary>Flash</UploadPicturePrimary>
          </UploadPictureTitle>
          <UploadPictureSubtitle>
            Upload a picture of your trash and sit back and let us help you find
            cost-saving opportunities
          </UploadPictureSubtitle>
          <UploadPictureMessage>
            Step 2/2: Upload a picture of a side of your bin.
          </UploadPictureMessage>
          <PhotoUpload
            label="Drag & Drop Your Dumpster Photo Here"
            uploadPhoto={handlePhotoUpload}
          />
          <Button
            className="opposite back"
            onClick={() => goToUploadStep(UPLOAD_STEPS.FIRST)}
          >
            BACK
          </Button>
        </Fragment>
      )}
    </UploadPicture>
  );
}
