import { httpSnap } from "services/http";
import { MaterialsData } from "interfaces/materials";

const transformMaterialsData = (data: MaterialsData) =>
  data.map((material) => ({ name: material.key, percent: material.value }));

export const sendTweaked = (uuid: string, data: MaterialsData) =>
  httpSnap
    .post(`tweak/${uuid}`, transformMaterialsData(data))
    .then((response) => response.data);
