import ArsMaquetteBoldWebFontEot from "assets/fonts/ars-maquette/ars-maquette-bold-webfont.eot";
import ArsMaquetteBoldWebFontEotIe from "assets/fonts/ars-maquette/ars-maquette-bold-webfont.eot?#iefix";
import ArsMaquetteBoldWebFontTtf from "assets/fonts/ars-maquette/ars-maquette-bold-webfont.ttf";
import ArsMaquetteBoldWebFontWoff from "assets/fonts/ars-maquette/ars-maquette-bold-webfont.woff";
import ArsMaquetteBoldWebFontWoff2 from "assets/fonts/ars-maquette/ars-maquette-bold-webfont.woff2";

import ArsMaquetteMediumWebFontEot from "assets/fonts/ars-maquette/ars-maquette-medium-webfont.eot";
import ArsMaquetteMediumWebFontEotIe from "assets/fonts/ars-maquette/ars-maquette-medium-webfont.eot?#iefix";
import ArsMaquetteMediumWebFontTtf from "assets/fonts/ars-maquette/ars-maquette-medium-webfont.ttf";
import ArsMaquetteMediumWebFontWoff from "assets/fonts/ars-maquette/ars-maquette-medium-webfont.woff";
import ArsMaquetteMediumWebFontWoff2 from "assets/fonts/ars-maquette/ars-maquette-medium-webfont.woff2";

import ArsMaquetteRegularWebFontEot from "assets/fonts/ars-maquette/ars-maquette-regular-webfont.eot";
import ArsMaquetteRegularWebFontEotIe from "assets/fonts/ars-maquette/ars-maquette-regular-webfont.eot?#iefix";
import ArsMaquetteRegularWebFontTtf from "assets/fonts/ars-maquette/ars-maquette-regular-webfont.ttf";
import ArsMaquetteRegularWebFontWoff from "assets/fonts/ars-maquette/ars-maquette-regular-webfont.woff";
import ArsMaquetteRegularWebFontWoff2 from "assets/fonts/ars-maquette/ars-maquette-regular-webfont.woff2";

import ArsMaquetteLightWebFontEot from "assets/fonts/ars-maquette/ars-maquette-light-webfont.eot";
import ArsMaquetteLightWebFontEotIe from "assets/fonts/ars-maquette/ars-maquette-light-webfont.eot?#iefix";
import ArsMaquetteLightWebFontTtf from "assets/fonts/ars-maquette/ars-maquette-light-webfont.ttf";
import ArsMaquetteLightWebFontWoff from "assets/fonts/ars-maquette/ars-maquette-light-webfont.woff";
import ArsMaquetteLightWebFontWoff2 from "assets/fonts/ars-maquette/ars-maquette-light-webfont.woff2";

const fonts = `

  // Fonts
  // --------------------- 

  @font-face {
    font-family: "Ars Maquette";
    src: url(${ArsMaquetteBoldWebFontEot});
    src: url(${ArsMaquetteBoldWebFontEotIe}) format("embedded-opentype"),
      url(${ArsMaquetteBoldWebFontTtf}) format("truetype"),
      url(${ArsMaquetteBoldWebFontWoff}) format("woff"),
      url(${ArsMaquetteBoldWebFontWoff2}) format("woff2");
    font-weight: 600;
  }

  @font-face {
    font-family: "Ars Maquette";
    src: url(${ArsMaquetteMediumWebFontEot});
    src: url(${ArsMaquetteMediumWebFontEotIe}) format("embedded-opentype"),
      url(${ArsMaquetteMediumWebFontTtf}) format("truetype"),
      url(${ArsMaquetteMediumWebFontWoff}) format("woff"),
      url(${ArsMaquetteMediumWebFontWoff2}) format("woff2");
    font-weight: 500;
  }

  @font-face {
    font-family: "Ars Maquette";
    src: url(${ArsMaquetteRegularWebFontEot});
    src: url(${ArsMaquetteRegularWebFontEotIe}) format("embedded-opentype"),
      url(${ArsMaquetteRegularWebFontTtf}) format("truetype"),
      url(${ArsMaquetteRegularWebFontWoff}) format("woff"),
      url(${ArsMaquetteRegularWebFontWoff2}) format("woff2");
    font-weight: 400;
  }

  @font-face {
    font-family: "Ars Maquette";
    src: url(${ArsMaquetteLightWebFontEot});
    src: url(${ArsMaquetteLightWebFontEotIe}) format("embedded-opentype"),
      url(${ArsMaquetteLightWebFontTtf}) format("truetype"),
      url(${ArsMaquetteLightWebFontWoff}) format("woff"),
      url(${ArsMaquetteLightWebFontWoff2}) format("woff2");
    font-weight: 300;
  }
`;

export default fonts;
