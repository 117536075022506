import {
  AboutDescription,
  AboutWrapper,
  AboutTitleSection,
  AboutTitle,
  Container,
} from "components/shared";

export default function About() {
  return (
    <Container>
      <AboutWrapper>
        <AboutTitle>
          About RUBICON<AboutTitleSection>Flash</AboutTitleSection>
        </AboutTitle>
        <AboutDescription>
          Changing the rules in waste and recycling
        </AboutDescription>
      </AboutWrapper>
    </Container>
  );
}
