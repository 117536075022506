import styled from "styled-components";

import Devices from "theme/devices";

export const ChartWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  & > * {
    padding: 0 35px;
  }
  @media ${Devices.mobile} {
    flex-direction: column;
  }
`;

export const DoughnutChart = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-right: 70px;
  @media ${Devices.tablet} {
    padding: 0 2%;
  }
`;

export const ChartLegend = styled.div`
  flex: 3;
  display: flex;
  padding: 0;
  @media ${Devices.mobile} {
    width: 100%;
  }
`;

export const MaterialList = styled.ul`
  flex: 1;
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: left;
`;

export const MaterialItem = styled.li`
  display: flex;
  align-items: center;
  line-height: 53px;
  font-size: 20px;
`;

export const MaterialBullet = styled.span`
  font-size: 40px;
  line-height: 0px;
  padding-right: 12px;
  @media ${Devices.tablet} {
    padding-right: 5px;
  }
`;

export const MaterialPercent = styled.span`
  padding-right: 12px;
  font-size: 24px;
  @media ${Devices.tablet} {
    padding-right: 5px;
  }
  @media ${Devices.mobile} {
    font-size: 20px;
  }
`;

export const MaterialName = styled.span`
  font-size: 14px;
  @media ${Devices.tablet} {
    line-height: 20px;
  }
`;

export const MaterialAmount = styled.li`
  width: 85%;
  margin-top: -9px;
  margin-bottom: 12px;
`;

// Range slider
// ------------------
export const MaterialInput = styled.input`
  -webkit-appearance: none;
  width: 100%;
  margin: 12.5px 0;
  &:focus {
    outline: none;
  }
  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 1px;
    cursor: pointer;
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    background: rgba(46, 0, 0, 0.78);
    border-radius: 3.9px;
    border: 0px solid #000101;
  }
  &::-webkit-slider-thumb {
    box-shadow: 0.9px 0.9px 1px #000031, 0px 0px 0.9px #00004b;
    border: 0px solid #ffffff;
    height: 28px;
    width: 28px;
    border-radius: 50px;
    background: #ffffff;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -12.5px;
  }
  &:focus::-webkit-slider-runnable-track {
    background: rgba(72, 0, 0, 0.78);
  }
  &::-moz-range-track {
    width: 100%;
    height: 1px;
    cursor: pointer;
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    background: rgba(46, 0, 0, 0.78);
    border-radius: 3.9px;
    border: 0px solid #000101;
  }
  &::-moz-range-thumb {
    box-shadow: 0.9px 0.9px 1px #000031, 0px 0px 0.9px #00004b;
    border: 0px solid #ffffff;
    height: 28px;
    width: 28px;
    border-radius: 50px;
    background: #ffffff;
    cursor: pointer;
  }
  &::-ms-track {
    width: 100%;
    height: 1px;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }
  &::-ms-fill-lower {
    background: rgba(20, 0, 0, 0.78);
    border: 0px solid #000101;
    border-radius: 7.8px;
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  }
  &::-ms-fill-upper {
    background: rgba(46, 0, 0, 0.78);
    border: 0px solid #000101;
    border-radius: 7.8px;
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  }
  &::-ms-thumb {
    box-shadow: 0.9px 0.9px 1px #000031, 0px 0px 0.9px #00004b;
    border: 0px solid #ffffff;
    height: 28px;
    width: 28px;
    border-radius: 50px;
    background: #ffffff;
    cursor: pointer;
    height: 1px;
  }
  &:focus::-ms-fill-lower {
    background: rgba(46, 0, 0, 0.78);
  }
  &:focus::-ms-fill-upper {
    background: rgba(72, 0, 0, 0.78);
  }
`;
