import { Container, Image } from "components/shared";
import CostSavings from "assets/images/icon-cost-savings.svg";
import SitBack from "assets/images/icon-sit-back.svg";
import SnapYourTrash from "assets/images/icon-snap-your-trash.svg";
import {
  Sticker,
  StickerDescription,
  StickerExplain,
  StickersWrapper,
  StickerTitle,
} from "components/shared";

export default function StickersSteps() {
  return (
    <Container className="stickers">
      <StickersWrapper>
        <Sticker>
          <Image src={SnapYourTrash} />
          <StickerExplain>
            <StickerDescription>Step 1:</StickerDescription>
            <StickerTitle>Snap It</StickerTitle>
          </StickerExplain>
        </Sticker>
        <Sticker>
          <Image src={SitBack} />
          <StickerExplain>
            <StickerDescription>Step 2:</StickerDescription>
            <StickerTitle>Load It</StickerTitle>
          </StickerExplain>
        </Sticker>
        <Sticker>
          <Image src={CostSavings} />
          <StickerExplain>
            <StickerDescription>Step 3:</StickerDescription>
            <StickerTitle>Save It</StickerTitle>
          </StickerExplain>
        </Sticker>
      </StickersWrapper>
    </Container>
  );
}
