import {
  Container,
  CustomerSubtitle,
  CustomerSubtitleBold,
  CustomerSubtitleCopy,
  CustomerSubtitlePrimary,
} from "components/shared";

export default function StepsCaption() {
  return (
    <Container>
      <CustomerSubtitle>
        <CustomerSubtitleCopy>
          <CustomerSubtitleBold>RUBICON</CustomerSubtitleBold>
          <CustomerSubtitlePrimary>Flash</CustomerSubtitlePrimary>
          &nbsp;is an easy-to-use application that analyzes photos of dumpster
          trash to help business owners understand the savings that can be made
          by diverting waste away from landfills
        </CustomerSubtitleCopy>
      </CustomerSubtitle>
    </Container>
  );
}
