import React, { useState } from "react";

import { Normalize } from "styled-normalize";
import { ThemeProvider } from "styled-components";

import About from "components/About";
import CustomerForm from "components/CustomerForm";
import Footer from "components/Footer";
import GlobalStyle from "theme/globalStyle";
import NavigationBar from "components/NavigationBar";
import StepsCaption from "components/StepsCaption";
import StickersAbout from "components/StickersAbout";
import StickersSteps from "components/StickersSteps";
import theme from "theme/theme";
import UploadPictureActions from "components/UploadPictureActions";
import { useValue } from "utils/hooks";
import { Customer } from "interfaces/customer";

export default function App() {
  const [customerData, setCustomerData] = useValue<Customer>(null);
  const [customerSubmitted, setCustomerSubmitted] = useState(false);

  const uploadPictureActionsSectionRef = React.useRef<HTMLDivElement>(null);
  const scrollToUploadPictureActionsSection = () =>
    uploadPictureActionsSectionRef.current?.scrollIntoView({
      behavior: "smooth",
    });

  return (
    <ThemeProvider theme={theme}>
      <Normalize />
      <GlobalStyle />
      <NavigationBar />
      <CustomerForm
        customerData={customerData}
        setCustomerData={setCustomerData}
        customerSubmitted={customerSubmitted}
        setCustomerSubmitted={setCustomerSubmitted}
        scrollToUploadPictureActionsSection={
          scrollToUploadPictureActionsSection
        }
      />
      <StepsCaption />
      <StickersSteps />
      <UploadPictureActions
        ref={uploadPictureActionsSectionRef}
        customerData={customerData}
        customerSubmitted={customerSubmitted}
        scrollToUploadPictureActionsSection={
          scrollToUploadPictureActionsSection
        }
      />
      <About />
      <StickersAbout />
      <Footer />
    </ThemeProvider>
  );
}
