import styled from "styled-components";

import Devices from "theme/devices";
import theme from "theme/theme";

export const GetStartedTitle = styled.h1`
  text-align: center;
  @media ${Devices.mobile} {
    font-size: 40px;
  }
`;

export const GetStartedPrimary = styled.span`
  color: ${theme.brandPrimary};
`;

export const GetStartedSubtitle = styled.p`
  text-align: center;
  margin-bottom: 82px;
  @media ${Devices.mobile} {
    margin-bottom: 50px;
  }
`;

export const GetStartedInstructions = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  @media ${Devices.tablet} {
    flex-direction: column;
  }
`;

export const InstructionsImageWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  padding: 0 25px;
  @media ${Devices.mobile} {
    padding: 0 10px;
  }
`;

export const InstructionsImage = styled.img`
  max-width: 100%;
  align-self: center;
`;

export const InstructionsInformation = styled.div`
  flex: 1;
  padding: 0 25px;
  @media ${Devices.tablet} {
    padding-top: 20px;
  }
  @media ${Devices.mobile} {
    padding: 0 10px;
  }
  button {
    &.getStarted {
      display: block;
      margin-top: 37px;
      color: white;
      &[disabled] {
        opacity: 0.4;
      }
      @media ${Devices.tablet} {
        margin: 37px auto 0;
      }
      @media ${Devices.mobile} {
        margin: 37px auto;
      }
    }
  }
`;

export const InstructionsInformationTitle = styled.h4`
  color: ${theme.brandPrimary};
  padding-bottom: 20px;
  font-weight: ${theme.fontWeightNormal};
  @media ${Devices.mobile} {
    text-align: center;
  }
`;

export const InstructionsInformationSubcategory = styled.h4`
  font-size: 19px;
  font-weight: ${theme.fontWeightNormal};
  &:last-of-type {
    padding-top: 20px;
  }
`;

export const InstructionsInformationList = styled.ol`
  font-weight: 700;
  font-size: 18px;
`;

export const InstructionsInformationListItem = styled.li`
  padding-left: 10px;
  line-height: 30px;
  color: ${theme.grayDarker};
`;

export const InstructionsInformationListSubItem = styled.div`
  font-weight: ${theme.fontWeightNormal};
  font-size: 14px;
  color: ${theme.grayDark};
`;

export const GetStartedWarning = styled.p`
  padding-top: 10px;
  color: ${theme.brandPrimary};
  @media ${Devices.mobile} {
    text-align: center;
  }
`;
