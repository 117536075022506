import styled, { keyframes } from "styled-components";

import theme from "theme/theme";

export const SkCubeGrid = styled.div`
  width: 75px;
  height: 75px;
  margin: 40px auto;
`;

export const SkcubeGridScaleDelay = keyframes`
  0%, 70%, 100% {
    -webkit-transform: scale3D(1, 1, 1);
            transform: scale3D(1, 1, 1);
  } 35% {
    -webkit-transform: scale3D(0, 0, 1);
            transform: scale3D(0, 0, 1);
  } 
`;

export const SkCube = styled.div`
  width: 33%;
  height: 33%;
  background-color: ${theme.brandPrimary};
  float: left;
  animation: ${SkcubeGridScaleDelay} 1.3s infinite ease-in-out;
  &.skCube1 {
    animation-delay: 0.2s;
  }
  &.skCube2 {
    animation-delay: 0.3s;
  }
  &.skCube3 {
    animation-delay: 0.4s;
  }
  &.skCube4 {
    animation-delay: 0.1s;
  }
  &.skCube5 {
    animation-delay: 0.2s;
  }
  &.skCube6 {
    animation-delay: 0.3s;
  }
  &.skCube7 {
    animation-delay: 0s;
  }
  &.skCube8 {
    animation-delay: 0.1s;
  }
  &.skCube9 {
    animation-delay: 0.2s;
  }
`;
