import styled from "styled-components";

import theme from "theme/theme";

// Button placeholder
// -------------------
export const button = `
  position: relative;
  display: inline-block;
  padding: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  text-align: center;
  user-select: none;
`;

// Button
// -------

export const Button = styled.button`
  padding: 11px 36.5px 9px;
  border: none;
  border-radius: 1px;
  text-transform: uppercase;
  letter-spacing: 1px;
  line-height: 18px;
  font-weight: ${theme.fontWeightMedium};
  font-size: 14px;
  line-height: 22px;
  background: ${theme.brandPrimary};
  color: white;
  cursor: pointer;

  &[disabled] {
    opacity: 0.4;
  }

  & + & {
    margin-left: 10px;
  }

  &.opposite {
    background: transparent;
    border: 1px solid ${theme.brandPrimary};
    color: ${theme.brandPrimary};
  }

  &.back {
    margin-top: 40px;
    text-align: center;
  }

  .icon {
    position: absolute;
    top: 9px;
    left: 20px;
    font-size: 16px;
  }
`;
