import styled from "styled-components";

import Devices from "theme/devices";

export const Snap = styled.div`
  padding: 20px 0 55px;
  @media ${Devices.tablet} {
    padding-bottom: 0px;
  }
`;
