import styled from "styled-components";

import Devices from "theme/devices";
import theme from "theme/theme";
import headerBg from "assets/images/header-bg.png";

export const CustomerWrapper = styled.div`
  width: 100%;
  background: url(${headerBg});
  background-repeat: no-repeat;
  border-bottom: 10px solid #01a499;
  background-position: top center;
  color: white;
  margin: 0 auto;
  @media ${Devices.mobile} {
    margin-bottom: 0;
  }
`;

export const CustomerContent = styled.div`
  @media ${Devices.desktop} {
    min-height: 450px;
    padding-top: 35px;
  }
  @media ${Devices.mobile} {
    padding: 15px 0 0;
    min-height: 390px;
  }
`;

export const CustomerDescription = styled.div`
  min-height: 620px;
  max-width: 1146px;
  margin: 0 auto;
  padding-top: 50px;
  display: flex;
  flex: 1;
  @media ${Devices.desktop} {
    flex: 3;
  }
  @media ${Devices.mobile} {
    min-height: 535px;
    flex-direction: column;
    padding-top: 0px;
    text-align: center;
  }
`;

export const CustomerText = styled.div`
  flex: 7;
  padding-left: 50px;
  width: 100%;
  @media ${Devices.mobile} {
    padding-left: 0;
  }
`;

export const CustomerTextCopy = styled.h2`
  @media (max-width: 1010px) {
    font-size: 38px;
  }
  @media ${Devices.mobile} {
    font-size: 31px;
    padding: 0 15px;
  }
`;

export const CustomerPicture = styled.img`
  margin: 0 auto;
  @media ${Devices.mobile} {
    padding-top: 25px;
    width: 65%;
  }
`;

export const CustomerTextForm = styled.div`
  margin-top: 40px;
  button {
    &.customerSubmit {
      @media ${Devices.mobile} {
        margin-top: 10px;
      }
    }
  }
`;

export const CustomerLoadingCubeText = styled.h4`
  color: ${theme.brandPrimary};
  text-align: center;
`;
