export const materials = [
  { key: "Background", name: "Background", color: { r: 42, g: 182, b: 246 } },
  { key: "OCC", name: "Cardboard", color: { r: 117, g: 112, b: 242 } },
  { key: "Plastic", name: "Plastic", color: { r: 255, g: 87, b: 35 } },
  { key: "Paper", name: "Paper", color: { r: 77, g: 175, b: 80 } },
  { key: "Organic", name: "Organic", color: { r: 205, g: 218, b: 73 } },
  { key: "Metal", name: "Metal", color: { r: 97, g: 125, b: 138 } },
  { key: "BPB", name: "Plastic Bags", color: { r: 120, g: 85, b: 73 } },
  { key: "Cans", name: "Cans", color: { r: 127, g: 0, b: 165 } },
  { key: "Bottles", name: "Bottles", color: { r: 165, g: 101, b: 0 } },
  { key: "Other", name: "Other", color: { r: 255, g: 193, b: 7 } },
];
