import Environments from "./environments";

export const BASE_URL_MAP: { [url: string]: Environments } = {
  localhost: Environments.local,
  "flash.dev.aws.rubiconglobal.com": Environments.development,
  "flash.dev.aws.rubicon.com": Environments.development,
  "flash.qa.aws.rubiconglobal.com": Environments.qa,
  "flash.qa.aws.rubicon.com": Environments.qa,
  "flash.prod.aws.rubiconglobal.com": Environments.production,
  "flash.rubiconglobal.com": Environments.production,
  "flash.rubicon.com": Environments.production,
};

export const API_URL_MAP = {
  flashApi: {
    // 'http://localhost:57199',
    [Environments.local]: "https://flash-api.qa.aws.rubiconglobal.com",
    [Environments.development]: "https://flash-api.qa.aws.rubiconglobal.com",
    [Environments.qa]: "https://flash-api.qa.aws.rubiconglobal.com",
    [Environments.production]: "https://flash-api.prod.aws.rubiconglobal.com",
  },
};
