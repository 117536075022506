import { httpSnap } from "services/http";
import { materials } from "constants/materials";
import { MaterialsAmount } from "interfaces/materials";

export enum PHOTO_TYPE {
  CLASSIFY = "classify",
  TRASH_BIN = "trashbin",
}

const transformMaterialsData = (materialsAmount: MaterialsAmount) =>
  materials
    .map((materialItem) => ({
      ...materialItem,
      value:
        materialsAmount.find((material) => material.Name === materialItem.key)
          ?.Percent || 0,
    }))
    .sort((a, b) => b.value - a.value);

export const uploadPhoto = (
  file: File,
  uuid: string,
  photoType: PHOTO_TYPE
) => {
  const formData = new FormData();
  formData.append("img_file", file);
  formData.append("uuid", uuid);

  return httpSnap
    .post(photoType, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) =>
      photoType === PHOTO_TYPE.CLASSIFY
        ? transformMaterialsData(response.data)
        : response.data
    );
};
