import React, { Fragment, useState } from "react";

import { Button } from "components/Button";
import { Customer } from "interfaces/customer";
import {
  FeedbackContent,
  FeedbackForm,
  FeedbackFormButtons,
  FeedbackFormInputs,
  FeedbackFormInputsLeft,
  FeedbackFormInputsRight,
  FeedbackFormTitle,
  FeedbackLoadingCube,
  FeedbackLoadingCubeText,
  FeedbackSubtitle,
  FeedbackTitle,
} from "./styled";
import {
  FormControl,
  FormControlSelect,
  FormGroup,
  FormGroupHelp,
  Label,
} from "components/Form";
import { preventSubmitOnEnter } from "utils";
import { sendFeedback } from "services/customer";
import { UPLOAD_STEPS } from "components/UploadPictureActions";
import { useInput } from "utils/hooks";
import { validPhoneNumberRegex } from "constants/regex";
import LoadingCube from "components/LoadingCube";

interface OptionType {
  name: string;
  value: string;
}

const BIN_EMPTIED_FREQUENCY: OptionType[] = [
  { name: "1x per week", value: "1x per week" },
  { name: "2x per week", value: "2x per week" },
  { name: "3x per week", value: "3x per week" },
  { name: "4x per week", value: "4x per week" },
  { name: "5x+ per week", value: "5x+ per week" },
];

type Props = {
  customerData: Customer;
  scrollToUploadPictureActionsSection: () => void;
  goToUploadStep: (step: UPLOAD_STEPS) => void;
};

export default function Feedback({
  customerData,
  scrollToUploadPictureActionsSection,
  goToUploadStep,
}: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const [feedbackSent, setFeedbackSent] = useState(false);

  const firstName = useInput("");
  const lastName = useInput("");
  const frequency = useInput("");
  const bill = useInput("");
  const company = useInput("");
  const phoneNumber = useInput("");

  const handleSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault();
    if (
      !firstName.value ||
      !lastName.value ||
      !frequency.value ||
      !bill.value ||
      !company.value
    ) {
      if (!firstName.value) {
        firstName.setError("You can't leave this empty.");
      }
      if (!lastName.value) {
        lastName.setError("You can't leave this empty.");
      }
      if (!frequency.value) {
        frequency.setError("You can't leave this empty.");
      }
      if (!bill.value) {
        bill.setError("You can't leave this empty.");
      }
      if (!company.value) {
        company.setError("You can't leave this empty.");
      }
      if (!phoneNumber.value) {
        phoneNumber.setError("You can't leave this empty.");
      }
      return;
    }

    if (
      !(bill.value > 0) ||
      !validPhoneNumberRegex.test(String(phoneNumber.value))
    ) {
      if (!(bill.value > 0)) {
        bill.setError("Value must be greater than 0");
      }
      if (!validPhoneNumberRegex.test(String(phoneNumber.value))) {
        phoneNumber.setError("Incorrect phone number format.");
      }
      return;
    }

    if (!customerData) return;

    setIsLoading(true);
    scrollToUploadPictureActionsSection();

    sendFeedback({
      email: customerData.email,
      zipcode: customerData.zipcode,
      uuid: customerData.uuid,
      firstName: String(firstName.value),
      lastName: String(lastName.value),
      frequency: String(frequency.value),
      trashBill: Number(bill.value),
      company: String(company.value),
      phone: String(phoneNumber.value),
    })
      .then(() => {
        setFeedbackSent(true);
        setIsLoading(false);
        goToUploadStep(UPLOAD_STEPS.DOWNLOAD);
      })
      .catch(() => {
        window.alert("Try Again");
        setIsLoading(false);
      });
  };

  return (
    <Fragment>
      <FeedbackTitle>Let us help you save money</FeedbackTitle>
      <FeedbackSubtitle>
        Customers with a similar waste stream to you have saved money with our
        recycling services
      </FeedbackSubtitle>
      <FeedbackContent>
        {(!feedbackSent || !isLoading) && (
          <FeedbackForm>
            <form
              name="feedback"
              onSubmit={handleSubmit}
              noValidate
              onKeyPress={preventSubmitOnEnter}
            >
              <FeedbackFormTitle>Get Started</FeedbackFormTitle>
              <FeedbackFormInputs>
                <FeedbackFormInputsLeft>
                  <FormGroup
                    className="withLabel customMargin"
                    hasError={!!firstName.error}
                  >
                    <Label>* First Name:</Label>
                    <FormControl
                      type="text"
                      name="firstName"
                      {...firstName.bind}
                      placeholder="First Name"
                      required
                      className="moreSpace"
                    />
                    {firstName.error && (
                      <FormGroupHelp>
                        <span>{firstName.error}</span>
                      </FormGroupHelp>
                    )}
                  </FormGroup>

                  <FormGroup className="withLabel" hasError={!!lastName.error}>
                    <Label>* Last Name:</Label>
                    <FormControl
                      type="text"
                      name="lastName"
                      {...lastName.bind}
                      placeholder="Last Name"
                      required
                      className="moreSpace"
                    />
                    {lastName.error && (
                      <FormGroupHelp>
                        <span>{lastName.error}</span>
                      </FormGroupHelp>
                    )}
                  </FormGroup>
                  <FormGroup className="withLabel" hasError={!!frequency.error}>
                    <Label>* How often is your bin emptied?</Label>
                    <FormControlSelect
                      name="frequency"
                      {...frequency.bind}
                      required
                      className="moreSpace"
                    >
                      <option value="" disabled>
                        Frequency
                      </option>
                      {BIN_EMPTIED_FREQUENCY.map((option: OptionType) => (
                        <option value={option.value} key={option.value}>
                          {option.name}
                        </option>
                      ))}
                    </FormControlSelect>
                    {frequency.error && (
                      <FormGroupHelp>
                        <span>{frequency.error}</span>
                      </FormGroupHelp>
                    )}
                  </FormGroup>
                </FeedbackFormInputsLeft>
                <FeedbackFormInputsRight>
                  <FormGroup className="withLabel" hasError={!!bill.error}>
                    <Label>
                      * All fees included, about how much is your monthly trash
                      bill?
                    </Label>
                    <FormControl
                      type="number"
                      name="bill"
                      min="1"
                      {...bill.bind}
                      placeholder="Trash Bill"
                      required
                      className="moreSpace"
                    />
                    {bill.error && (
                      <FormGroupHelp>
                        <span>{bill.error}</span>
                      </FormGroupHelp>
                    )}
                  </FormGroup>
                  <FormGroup className="withLabel" hasError={!!company.error}>
                    <Label>* Company:</Label>
                    <FormControl
                      type="text"
                      name="company"
                      {...company.bind}
                      placeholder="Company"
                      required
                      className="moreSpace"
                    />
                    {company.error && (
                      <FormGroupHelp>
                        <span>{company.error}</span>
                      </FormGroupHelp>
                    )}
                  </FormGroup>
                  <FormGroup
                    className="withLabel"
                    hasError={!!phoneNumber.error}
                  >
                    <Label>* Work Phone:</Label>
                    <FormControl
                      type="text"
                      name="phoneNumber"
                      {...phoneNumber.bind}
                      placeholder="Work Phone"
                      required
                      className="moreSpace"
                    />
                    {phoneNumber.error && (
                      <FormGroupHelp>
                        <span>{phoneNumber.error}</span>
                      </FormGroupHelp>
                    )}
                  </FormGroup>
                </FeedbackFormInputsRight>
              </FeedbackFormInputs>
              <FeedbackFormButtons>
                <Button
                  className="opposite"
                  onClick={() => goToUploadStep(UPLOAD_STEPS.RESULTS)}
                >
                  BACK
                </Button>
                <Button type="submit">SUBMIT</Button>
              </FeedbackFormButtons>
            </form>
          </FeedbackForm>
        )}
        {isLoading && (
          <FeedbackLoadingCube>
            <FeedbackLoadingCubeText>
              Sending your data...
            </FeedbackLoadingCubeText>
            <LoadingCube />
          </FeedbackLoadingCube>
        )}
      </FeedbackContent>
    </Fragment>
  );
}
