import { useRef } from "react";
import { useState, ChangeEvent } from "react";

export function useInput(initialValue: string | number) {
  const [value, setValue] = useState(initialValue);
  const [error, setError] = useState<string | null>(null);

  const onChange = (
    event: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    if (error) setError(null);
    setValue(event.target.value);
  };

  return {
    bind: { value, onChange },
    value,
    error,
    setError,
  };
}

export function useValue<T>(initialValue: T): [T, (newValue: T) => void] {
  const value = useRef<T>(initialValue);

  const setValue = (newValue: T) => {
    value.current = newValue;
  };

  return [value.current, setValue];
}
